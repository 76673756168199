<template>
  <div class="app-project-progress-detail" v-if="detail">
    <div class="padding-08">
      <div class="padding-08 bold fc-t">
        <span>{{detail.task?.proName}} / {{detail.task?.stageName}} / {{detail.task?.name}}</span>
        <br />
        <span class="fs-huge">进度记录详情</span>
      </div>
      <div class="padding-08">{{ detail.recInfo || "暂无进度记录内容"}}</div>
    </div>
    
    <van-cell-group>
      <van-cell title="用时">
        <van-tag type="success">{{detail.costTime}}小时</van-tag>
      </van-cell>
      <van-cell title="剩余">
        <van-tag :type="detail.remaining > 0 ? 'primary' : 'default'">{{detail.remaining}}天</van-tag>
      </van-cell>
      <van-cell title="记录人" :value="detail.recUserName" />
      <van-cell title="记录时间" :value="detail.recTime" />
    </van-cell-group>
  </div>
</template>

<script>
import { list } from "@/api/project/progress";

export default {
  computed: {
    progressId() {
      return this.$route.params.progressId;
    },
  },
  data() {
    return {
      detail: null,
    };
  },
  mounted() {
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      list({ id: this.progressId, current: 1, size: 1 }).then((res) => {
        if (res?.content?.length) {
          this.detail = res.content[0];
        }
      });
    },
  },
};
</script>