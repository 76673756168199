import request from "@/utils/request";

export function list(params) {
  return request({
    url: "api/proTaskRec",
    method: "GET",
    params,
  });
}
export function add(data) {
  return request({
    url: "api/proTaskRec",
    method: "post",
    data,
  });
}



export default {
  list,add
};
